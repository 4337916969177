(function (App, $, window) {
  /** @type {Boolean} Is local debug active? */
  const localDebug = false;
  /** @type {Boolean} Is debug active? */
  const debug = App.hasDebug() && localDebug;
  /** @type {Function} Log helper */
  const { log } = App.components.Debugger(debug, 'Sticky');

  /**
   * Class Constructor
   *
   * @param  {jQuery} $items A list of sticky items
   * @return {Sticky}        A new instance of Sticky
   */
  function Sticky($items) {
    if (!(this instanceof Sticky)) return new Sticky();
    log('constructor');

    this.isLooping = false;
    this.pageYOffset = window.pageYOffset;

    this.$items = $items;
    this.store = [];

    this.scrollHandler = this.scrollHandler.bind(this);
    this.resizeHandler = this.resizeHandler.bind(this);
    this.testVisibility = this.testVisibility.bind(this);
    this.loop = this.loop.bind(this);

    this.init();

    return this;
  }

  /**
   * Initialize the component
   *
   * @return {Sticky} The current instance
   */
  Sticky.prototype.init = function () {
    log('init');

    window.addEventListener('scroll', this.scrollHandler, {
      passive: true,
    });
    window.addEventListener('resize', this.resizeHandler);

    const self = this;
    this.$items.each(function (index, item) {
      const $item = $(item);
      const options = $item.data('sticky');
      const $start = $(options.start);
      const $end = $(options.end);

      const obj = {
        el: item,
        $item,
        $start,
        $end,
        start: $start.offset().top - App.helpers.headerHeight,
        end: $end.offset().top - $item.outerHeight() - App.helpers.headerHeight,
        isSticky: false,
      };
      self.store.push(obj);

      // Test visibility on init
      self.testVisibility(index, obj);
    });
  };

  /**
   * Handler for the window `scroll` event
   */
  Sticky.prototype.scrollHandler = function () {
    if (!this.isLooping) {
      this.isLooping = true;
      this.loop();
    }
  };

  /**
   * Handler for the window `resize` event
   */
  Sticky.prototype.resizeHandler = function () {
    log('resizeHandler');

    clearTimeout(this.resizeTimer);
    this.resizeTimer = setTimeout(
      function () {
        this.saveSizes();
      }.bind(this),
      300
    );
  };

  /**
   * Save items sizes
   *
   * @return {void}
   */
  Sticky.prototype.saveSizes = function () {
    log('saveSizes');

    $.each(this.store, function (index, item) {
      item.start = item.$start.offset().top - App.helpers.headerHeight;
      item.end = item.$end.offset().top - item.$item.outerHeight() - App.helpers.headerHeight;
    });
  };

  /**
   * Main loop
   */
  Sticky.prototype.loop = function () {
    // Stop here if not looping anymore
    if (!this.isLooping) {
      return false;
    }

    // Disable loop if no scroll
    if (window.pageYOffset === this.pageYOffset) {
      this.isLooping = false;
      return requestAnimationFrame(this.loop);
    }

    // Check for sticky bars' visibility
    $.each(this.store, this.testVisibility);

    // Update previous scroll position
    this.pageYOffset = window.pageYOffset;

    return requestAnimationFrame(this.loop);
  };

  /**
   * Test a given item's visibility
   *
   * @param  {Number} index The index of the item
   * @param  {Object} item  The object created in the `init` method
   */
  Sticky.prototype.testVisibility = function (index, item) {
    const scrolled = window.pageYOffset;

    // Display if between start and end and not visible yet
    if (scrolled > item.start && scrolled < item.end && !item.isSticky) {
      item.isSticky = true;
      this.fixElement(item.el);
    }

    // Unfix if before start
    if (scrolled < item.start && item.isSticky) {
      item.isSticky = false;
      this.unfixElement(item.el);
    }

    // Fix it to bottom if below end
    if (scrolled > item.end && item.isSticky) {
      if (!item.isTransformed) {
        item.isTransformed = true;
      }
      this.transformElement(item.el, item.end - scrolled);
    }

    if (scrolled < item.end && item.isTransformed) {
      item.el.style.transform = '';
      item.isTransformed = false;
    }
  };

  /**
   * Fix the given element based on the given offset
   *
   * @param  {HTMLElement} element The element to fix
   * @param  {Number}      offset  The offset of the fixed position
   * @return {void}
   */
  Sticky.prototype.fixElement = function fixElement(element) {
    // Reset styles to default
    this.unfixElement(element);
    setTimeout(function () {
      log('fixElement');
      const sizes = element.getBoundingClientRect();
      element.style.position = 'fixed';
      element.style.top = `${App.helpers.headerHeight}px`;
      element.style.left = `${sizes.left}px`;
      element.style.width = `${sizes.width}px`;
      element.style.height = `${sizes.height}px`;
    }, 16);
  };

  /**
   * Unfix the given element
   *
   * @param  {HTMLElement} element The element to unfix
   * @return {void}
   */
  Sticky.prototype.unfixElement = function unfixElement(element) {
    log('unfixElement');

    element.style.position = '';
    element.style.top = '';
    element.style.left = '';
    element.style.width = '';
    element.style.height = '';
    element.style.transform = '';
  };

  /**
   * Translate an element to keep it in the right position after end has
   * been reached.
   *
   * @param  {HTMLElement} element    The element to translatr
   * @param  {Number}      translateY The value of the translation
   * @return {void}
   */
  Sticky.prototype.transformElement = function transformElement(element, translateY) {
    log('transformElement');
    element.style.transform = App.helpers.formatMatrix({
      translateY: Math.round(translateY),
    });
  };

  // Init a new instance
  window.addEventListener('load', function () {
    const $items = $('[data-sticky]');
    if ($items.length) {
      App.components.sticky = new Sticky($items);
    }
  });
})(App, jQuery, window);
